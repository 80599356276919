import { getNews } from '~/api/news';

/**
 *  middleware  - check  isset page
 */
export default defineNuxtRouteMiddleware(async to => {
    const nuxtApp = useNuxtApp();
    const locale = nuxtApp.$i18n.locale;
    const { data: news } = await useAsyncData(`news${to.params.id}`, () => getNews(to.params.id, locale.value));
    return news.value !== null && news.value !== undefined;
});
